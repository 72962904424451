import React from "react"
import { Modal } from "react-bootstrap"

const LegalStatement = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Aviso legal</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="legal-statement">
          <h4>Política de privacidad y protección de datos personales</h4>
          <p>
            Mediante este aviso, GOT IT CONSULTING SL (en adelante, GOT IT), con
            domicilio en C/ Aribau 191, 1-2, 08021 de Barcelona, registrada en
            el Registro GOT IT de Barcelona tomo 44577 folio 130, hoja 460356
            inscripción 1; informa a los usuarios del portal web GOTIT.ES acerca
            de su política de protección de datos de carácter personal (en
            adelante, “los Datos Personales”) para que los Usuarios determinen,
            libre y voluntariamente, si desean facilitar a GOT IT los Datos
            Personales que se les puedan requerir o que se puedan obtener de los
            Usuarios con ocasión de la suscripción o alta en algunos de los
            servicios ofrecidos por GOT IT en el Portal o a través del Portal.
            GOT IT se reserva el derecho a modificar la presente política para
            adaptarla a novedades legislativas o jurisprudenciales así como a
            prácticas de la industria.
          </p>
          <p>
            En dichos supuestos, GOT IT anunciará en esta página los cambios
            introducidos con razonable antelación a su puesta en práctica. A los
            efectos de la Ley Orgánica 15/1999, de 13 de diciembre, de
            Protección de Datos de Carácter Personal, le informamos que los
            datos recabados en los distintos formularios serán incluidos en un
            fichero de datos de carácter personal con el fin de tramitar
            adecuadamente su solicitud, y cuyo destinatario será GOT IT. GOT IT,
            como responsable del fichero, garantiza el ejercicio de los derechos
            de acceso, rectificación, cancelación y oposición de los datos
            facilitados. Para ello, y con el fin de facilitarle este trámite,
            bastará con que nos remita un mensaje con su petición, a la
            dirección electrónica:{" "}
            <a href="mailto:compliance@gotit.es">compliance@gotit.es</a>.
          </p>
          <p>
            De igual modo, se compromete, en la utilización de los datos
            incluidos en el fichero, a respetar su confidencialidad y a
            utilizarlos de acuerdo con la finalidad del fichero. Remitiendo la
            información solicitada en los formularios, el interesado consiente
            expresamente el tratamiento y cesión de sus datos a GOT IT, para el
            cumplimiento de la finalidad arriba indicada.
          </p>
          <p>
            En virtud de lo dispuesto en el RGPD y la Ley Orgánica 15/1999, de
            13 de diciembre, de Protección de Datos de Carácter Personal, le
            informamos que sus datos recabados en los distintos formularios
            serán incluidos en un fichero de GOT IT con la finalidad de tramitar
            su solicitud y gestionar adecuadamente la prestación de servicios.
            Dichos datos serán tratados asimismo para informarle y remitirle
            publicidad sobre promociones especiales relativas a servicios o
            productos relacionados con internet y la nuevas tecnologías. Si no
            desea que sus datos sean tratados con finalidad comercial, le
            rogamos comunique marque la casilla que le aparecerá en el momento
            de contratar el servicio. En la dirección electrónica:{" "}
            <a href="mailto:compliance@gotit.es">compliance@gotit.es</a> podrá
            ejercer los derechos de acceso rectificación, cancelación y
            oposición que la normativa le reconoce.
          </p>
          <h4>Navegación web. Cookies</h4>
          <p>
            Las cookies son pequeños archivos de texto que se instalan en el
            navegador del ordenador del Usuario para registrar su actividad,
            enviando una identificación anónima que se almacena en el mismo, con
            la finalidad de que la navegación sea más sencilla, permitiendo, por
            ejemplo, el acceso a los Usuarios que se hayan registrado
            previamente y el acceso a las áreas, servicios, promociones o
            concursos reservados exclusivamente a ellos sin tener que
            registrarse en cada visita. Se pueden utilizar también para medir la
            audiencia, parámetros del tráfico y navegación, tiempo de sesión,
            y/o controlar el progreso y el número de entradas.
          </p>
          <p>
            GOT IT procurará en todo momento establecer mecanismos adecuados
            para obtener el consentimiento del Usuario para la instalación de
            cookies que lo requieran. No obstante lo anterior, deberá tenerse en
            cuenta que, de conformidad con la Ley, se entenderá que el Usuario
            ha dado su consentimiento si modifica la configuración del navegador
            deshabilitando las restricciones que impiden la entrada de cookies y
            que el referido consentimiento no será preciso para la instalación
            de aquellas cookies que sean estrictamente necesarias para la
            prestación de un servicio expresamente solicitado por el Usuario
            (mediante registro previo).
          </p>
          <p>
            El Usuario tiene la posibilidad de configurar su navegador para ser
            avisado de la recepción de cookies y para impedir su instalación en
            su equipo.
          </p>
          <h4>Condiciones de Uso y su aceptación</h4>
          <p>
            Estas condiciones regulan el uso de la web de GOT IT para el usuario
            de Internet, y expresan la aceptación plena y sin reservas del mismo
            de todas y cada una de las condiciones y restricciones que estén
            publicadas en la web en el momento en que acceda a la misma. El
            acceso a la web y/o la utilización de cualquiera de los servicios en
            ella incluidos supondrá la aceptación de todas las condiciones de
            uso. GOT IT se reserva el derecho a modificar unilateralmente la web
            y los servicios en ella ofrecidos, incluyendo la modificación de las
            condiciones de uso. Por ello se recomienda al usuario que lea este
            Aviso Legal tantas veces como acceda a la web.
          </p>
          <h4>Condiciones de utilización de la Web</h4>
          <p>
            El usuario debe utilizar la web de conformidad con los usos
            autorizados. Queda expresamente prohibida la utilización de la web o
            de cualquiera de sus servicios con fines o efectos ilícitos,
            contrarios a la buena fe, al orden público o a lo establecido en las
            condiciones de uso. Queda igualmente prohibido cualquier uso lesivo
            de derechos o intereses de terceros o que, de cualquier forma,
            dificulten la utilización por parte de otros usuarios la normal
            utilización de la web y/o sus servicios. La utilización de la web es
            gratuita para el usuario.
          </p>
          <h4>Derechos de Propiedad Intelectual e Industrial</h4>
          <p>
            Todos los contenidos de la web (marca, nombres comerciales,
            imágenes, iconos, diseño y presentación general de las diferentes
            secciones) están sujetos a derechos de propiedad intelectual o
            industrial de GOT IT o de terceros. En ningún caso el acceso a la
            web implica que por parte de GOT IT o del titular de esos derechos:
            (1) se otorgue autorización o licencia alguna sobre esos contenidos,
            (2) se renuncie, transmita o ceda total o parcialmente ninguno de
            sus derechos sobre esos contenidos (entre otros, sus derechos de
            reproducción, distribución y comunicación pública). No podrán
            realizarse utilizaciones de la web y/o de sus contenidos, diferentes
            de las expresamente autorizadas por GOT IT. Ningún usuario de esta
            web puede revender, volver a publicar, imprimir, bajar, copiar,
            retransmitir o presentar ningún elemento de esta web ni de los
            contenidos de la misma sin el consentimiento previo por escrito de
            GOT IT, a menos que la ley permita, en medida razonable, copiar o
            imprimir los contenidos para uso personal y no comercial
            manteniéndose inalterados, en todo caso, el Copyright y demás datos
            identificativos de los derechos de GOT IT y/o del titular de los
            derechos de esos contenidos. Esta web y sus contenidos están
            protegidos por las leyes de protección de la Propiedad Industrial e
            Intelectual internacionales y de España, ya sea como obras
            individuales y/o como compilaciones. El usuario no puede borrar ni
            modificar de ninguna forma la información relativa a esos derechos
            incluidos en la web. Quedan reservados todos los derechos a favor de
            GOT IT o del titular de los mismos.
          </p>
          <h4>Exclusión de Garantías y Responsabilidad</h4>
          <h5>
            Exclusión de garantías y responsabilidad por el funcionamiento de la
            Web
          </h5>
          <p>
            GOT IT no garantiza y no asume ninguna responsabilidad por el
            funcionamiento de la web y/o sus servicios. En caso de producirse
            interrupciones en su funcionamiento GOT IT tratará, si esto fuera
            posible, de advertirlo al usuario. GOT IT tampoco garantiza la
            utilidad de la web y/o sus servicios para la realización de ninguna
            actividad en particular, su infalibilidad ni que el usuario pueda
            utilizar en todo momento la web o los servicios que se ofrezcan. GOT
            IT está autorizado a efectuar cuantas modificaciones técnicas sean
            precisas para mejorar la calidad, rendimiento, eficacia del sistema
            y de su conexión. Salvo que se indique expresamente un plazo, la
            prestación de los servicios tiene en principio una duración
            indefinida, no obstante, GOT IT está autorizado a dar por terminados
            alguno de los servicios o el acceso a la web en cualquier momento.
            Siempre que esto fuera posible, GOT IT tratará de comunicarlo con
            antelación al usuario.
          </p>
          <h5>Exclusión de garantías y responsabilidad por los contenidos</h5>
          <p>
            Los contenidos de todo tipo incluidos en la web que se hallan
            disponibles para el público en general, facilitan el acceso a
            información, productos y servicios suministrados o prestados por GOT
            IT. Dichos contenidos son facilitados de buena fe por GOT IT con
            información procedente, en ocasiones, de fuentes distintas a GOT IT.
            Por lo tanto, GOT IT no puede garantizar la fiabilidad, veracidad,
            exhaustividad y actualidad de los contenidos y, por tanto queda
            excluido cualquier tipo de responsabilidad de GOT IT que pudiera
            derivarse por los daños causados, directa o indirectamente, por la
            información a la que se acceda por medio de la web. GOT IT no
            garantiza la idoneidad de los contenidos incluidos en la web para
            fines particulares de quien acceda a la misma. En consecuencia,
            tanto el acceso a dicha web como el uso que pueda hacerse de la
            información y contenidos incluidos en las mismas se efectúa bajo la
            exclusiva responsabilidad de quien lo realice, y GOT IT no
            responderá en ningún caso y en ninguna medida por los eventuales
            perjuicios derivados del uso de la información y contenidos
            accesibles en la web. Asimismo, GOT IT no será en ningún caso
            responsable por productos o servicios prestados u ofertados por
            otras personas o entidades, o por contenidos, informaciones,
            comunicaciones, opiniones o manifestaciones de cualquier tipo
            originados o vertidos por terceros y a las que se pueda acceder a
            través de la web.
          </p>
          <h5>
            Exclusión de garantías y responsabilidad por enlaces a otras páginas
          </h5>
          <p>
            La web puede permitir al usuario su acceso por medio de enlaces a
            otras páginas web. GOT IT no responde ni hace suyo el contenido de
            las páginas web enlazadas, ni garantiza la legalidad, exactitud,
            veracidad y fiabilidad de la información que incluyan. La existencia
            de un enlace no supondrá la existencia de relación de ningún tipo
            entre GOT IT y la titular del sitio enlazado. GOT IT no tendrá
            ningún tipo de responsabilidad por infracciones o daños que se
            causen al usuario o a terceros por el contenido de las páginas web a
            las que se encuentre unidas por un enlace. Jurisdicción y ley
            aplicable. Las condiciones de uso y los servicios ofrecidos en la
            web se rigen por la Ley Española. GOT IT no tiene control alguno
            sobre quien o quienes pueden acceder a su web y donde pueden estar
            emplazados. A pesar de que GOT IT es consciente de ello, esto no
            significa que se someta a las jurisdicciones de países extranjeros;
            en caso de conflicto o reclamación en relación a la web o cualquiera
            de los servicios por ella prestados, las partes acuerdan someterse
            expresamente a los juzgados y tribunales de Barcelona (España).
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LegalStatement
