import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Header from "../components/header"

const Layout = ({ children, title, headerTransparent }) => (
    <>
      <SEO title={title} />
      <Header transparent={headerTransparent} />
      <div>{children}</div>
    </>
  )

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  headerTransparent: PropTypes.bool
}

export default Layout
