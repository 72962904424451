import React from "react"

const Slider = ({ title }) => (
  <div className="slider-activation">
    <div className="slide slide-style-1 slide-sub-page d-flex align-items-center bg_image bg_sub_page">
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner">
              <h2>{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Slider
