import React from "react"
import { Link } from "gatsby"
import { FiX, FiMenu } from "react-icons/fi"
import LogoGray from "../images/logo_gray_green_retina.svg";
import LogoWhite from "../images/logo_white_green_retina.svg";

const openRightMenu = () => {
  document.querySelector('.header-wrapper').classList.toggle('menu-open')
}

const closeRightMenu = () => {
  document.querySelector('.header-wrapper').classList.remove('menu-open');
}

const Header = ({ transparent = false }) => {
  let logo;
  const style = ["header-area", "formobile-menu"]

  if (transparent) {
    style.push("header--transparent")
    logo = LogoWhite
  }
  else {
    style.push("header--static")
    logo = LogoGray
  }

  return (
    <header className={style.join(" ")}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <Link to="/">
            <img src={logo} alt="GOT IT" />
          </Link>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <Link to="/">Qué es Got It</Link>
              </li>
              <li>
                <Link to="/team">Quiénes somos</Link>
              </li>
              <li>
                <Link to="/services">Qué hacemos</Link>
              </li>
              <li>
                <a href="/static/pdf/gotit_codigo_etico.pdf" target="_blank">Código ético</a>
              </li>
              <li>
                <Link to="/contact">Contacto</Link>
              </li>
            </ul>
          </nav>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span href="#" onClick={openRightMenu} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={closeRightMenu} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
