import React, { useState } from "react"
import LegalStatement from "../components/legalStatement"

const Footer = () => {
  const [legalVisible, setLegalVisible] = useState(false)
  const onHideLegal = () => setLegalVisible(false)

  const openLegalPopup = (e) => {
    e.preventDefault();
    setLegalVisible(true)
  }

  return (
    <>
      <LegalStatement show={legalVisible} onHide={onHideLegal} />
      <footer className="footer-area bg_footer">
        <div className="footer-wrapper">
          <div className="row align-items-end row--0">
            <div className="col-lg-6">
              <div className="footer-left">
                <div className="inner">
                  <h2>GOT IT?</h2>
                  <a className="rn-button-style--2" href="/contact">
                    <span>CONTACTO</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-right" data-black-overlay="6">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="footer-link">
                      <h4>Contacto</h4>
                      <ul className="ft-link">
                        <li>
                          Tel: <a href="tel:+34659210552">+34 659 210 552</a>
                        </li>
                        <li>
                          E-mail:{" "}
                          <a href="mailto:info@gotit.es">info@gotit.es</a>
                        </li>
                        <li>
                          <a href="/" onClick={openLegalPopup}>
                            Aviso legal
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                    <div className="footer-link">
                      <h4>Dirección</h4>
                      <span>
                        Got It Consulting S.L.
                        <br />
                        C/ Aribau 191, 1-2
                        <br />
                        08021 Barcelona
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="copyright-text">
                      <p>© GOT IT Consulting. Todos los derechos reservados.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
